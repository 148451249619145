import Axios from 'axios';
//import PagesQuery from './queries/pages.graphql';
import CraftVars from '../../services/craftVars';


let graphQLUrl = `${CraftVars.baseUrl}/graphql`;

export const CraftContent = {

  getEntries (settings = {}){

    const username = 'akustik'
    const password = 'akustikweb'

    const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')

    let queryVariables = {
      section : '',
      orderBy : 'postDate asc',
      ...settings
    };

    return new Promise((resolve) => {
      Axios({
        url: graphQLUrl,
        method: 'POST',
        headers: {
          'Authorization': `Basic ${token}`
        },
        data: {
          query: `query ($section: [String], $limit: Int, $offset: Int, $relatedTo: [QueryArgument]) {
            entries (section: $section, limit: $limit, offset: $offset, relatedTo: $relatedTo) {
              id
              slug
              title
              url
              ... on projects_project_Entry {
                teaser
                featureImage {
                   transform: imagerTransform(transform: "two") {
                    width
                    height
                    url
                    mimeType
                    size
                  }
                  title
                  ... on uploads_Asset {
                      alt
                  }
                }
              }
            }
          }`,
          variables: queryVariables
        }
      }).then((result) => {
        resolve(result.data);
      });
    });
  },

  getCategories (settings = {}){

    const username = 'akustik'
    const password = 'akustikweb'

    const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')

    let queryVariables = {
      section : '',
      ...settings
    };

    return new Promise((resolve) => {
      Axios({
        url: graphQLUrl,
        method: 'POST',
        headers: {
          'Authorization': `Basic ${token}`
        },
        data: {
          query: `query ($group: [String]) {
            categories (group: $group) {
              id
              title
            }
          }`,
          variables: queryVariables
        }
      }).then((result) => {
        resolve(result.data);
      });
    });
  }
}
