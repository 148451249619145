<template>
  <div
    class="filter__wrap"
    :class="[{'not-touch': !isMobileAndTouch}]">
    <div class="filter-box">
      <div class="filter field-group--select">
        <label for="materials">
          Schallabsorber
        </label>
        <select
          name="materials"
          id="materials"
          class="filter__selected"
          v-model="selectedMaterial"
          @change="selectChangeHandler"
        >
          <option
            v-for="item in materialOptions"
            :value="item.id"
          >
            {{ item.title }}
          </option>
        </select>

      </div>

      <div class="filter field-group--select">
        <label for="categories">
          Anwendungsbereich
        </label>
        <select
          name="categories"
          id="categories"
          class="filter__selected"
          v-model="selectedCategory"
          @change="selectChangeHandler"
        >
          <option
            v-for="item in projectCategories"
            :value="item.id"
          >
            {{ item.title }}
          </option>
        </select>

      </div>
    </div>

    <template v-if="posts.length">
      <div
        v-for="(post, key) in posts"
        class="row row--grid c-two_columns__item filterEntry--update"
        :class="[{'row--reverse': key % 2 === 1 }]"
        :key="key"
      >
        <div
          class="col col-sm-11 offset-md-2 col-md-8 offset-lg-0 col-lg-5 c-two_columns__item-image"
          :class="[{'offset-sm-1 dark-overlay': key % 2 === 1}, {'grey-overlay': key % 2 === 0}]"
        >
          <figure
            v-if="post.featureImage.length"
            class="figure figure-two"
          >
            <picture>
              <source :srcset="post.featureImage[0].transform[0].url" media="(max-width:660px)">
              <source :srcset="post.featureImage[0].transform[1].url" media="(max-width:660px)">
              <source :srcset="post.featureImage[0].transform[2].url" media="(max-width:767px)">
              <source :srcset="post.featureImage[0].transform[3].url" media="(max-width:767px)">
              <source :srcset="post.featureImage[0].transform[4].url" media="(min-width:800px)">
              <img
                :src="post.featureImage[0].transform[5].url"
                loading="lazy"
                :alt="post.featureImage[0].title || post.featureImage[0].alt"
                :width="post.featureImage[0].transform[5].width"
                :height="post.featureImage[0].transform[5].height"
                class="lazyload img-two"
              >
            </picture>
          </figure>
        </div>
        <div class="col col col-sm-12 offset-md-2 col-md-8 offset-lg-6 col-lg-6 c-two_columns__item-text styled">
          <h2>
            <span class="font-color-primary">{{ post.title }}–</span>
            <template v-if="post.teaser">
              <br>{{ post.teaser }}
            </template>

          </h2>
          <p>
            <a
              :href="post.url"
              class="action action-secondary"
            >
              Projekt ansehen
            </a>
          </p>

        </div>
      </div>

      <div class="filter__load-more">
        <button
          v-show="!noMorePosts"
          class="action action-primary"
          @click="loadMoreHandler">
          Mehr anzeigen
        </button>
      </div>
    </template>

    <p v-else-if="posts.length === 0 && !isLoading"
       class="text-align-center">
      Es gibt leider kein Ergebnis mit diesen Auswahlen.
    </p>
  </div>
</template>

<script>
import {CraftContent} from '../../api/craft/craftContent';
import isTouchDevice from 'is-touch-device';
import isMobile from 'ismobilejs';

export default {
  name: "FilterProjects",
  data () {
    return {
      materialOptions: [],
      projectCategories: [],
      selectedMaterial: '*',
      selectedCategory: '*',
      posts: [],
      categories: [],
      limit: 5,
      offset: 0,
      noMorePosts: true,
      isLoading: true,
    }
  },

  computed: {
    relationsQueryArray () {
      let temp = []

      if (this.selectedCategory !== '*') {
        temp = [this.selectedCategory, ...temp]
      }

      if (this.selectedMaterial !== '*') {
        temp = [this.selectedMaterial, ...temp]
      }

      if (temp.length > 1) {
        temp = ["and", ...temp]
      }

      return temp
    },

    isMobileAndTouch () {
      return isTouchDevice() && isMobile(window.navigator).any
    }
  },

  mounted() {
    const linkElem = document.createElement('link');
    linkElem.setAttribute('rel', 'stylesheet');
    linkElem.setAttribute('href', document.querySelector('meta[name="app-css"]').content);

    this.$el.appendChild(linkElem);

  },

  created() {

    CraftContent.getEntries(
      {
        section: "materials"
      }
    ).then(response => {
      this.materialOptions = [{id: '*', title: "Alle"}, ...response.data.entries]
      //console.log(response.data.entries)
    })

    CraftContent.getCategories(
      {
        group: "projectCategory"
      }
    ).then(response => {
      this.projectCategories = [{id: '*', title: "Alle"}, ...response.data.categories]
      //console.log(response.data.categories)
    })

    this.loadEntries()
  },

  methods: {
    loadEntries () {
      CraftContent.getEntries(
        {
          section: "projects",
          limit: this.limit,
          offset: this.offset,
          relatedTo: this.relationsQueryArray
        }
      ).then(response => {
        const temp = response.data.entries
        if (temp.length > (this.limit - 1)) {
          temp.pop()
          this.noMorePosts = false
        } else {
          this.noMorePosts = true
        }
        this.isLoading = false
        this.posts = [...this.posts, ...temp];
        this.offset = this.offset + this.limit - 1
      })
    },

    selectChangeHandler () {
      this.posts = []
      this.offset = 0
      this.isLoading = true
      this.loadEntries()
    },

    loadMoreHandler() {
      this.isLoading = true
      this.loadEntries()
    }
  }
}
</script>
