class ToggleElement {
  constructor(element) {
    this.element = element
    this.target = element.getAttribute('data-target')
    this.classes = element.getAttribute('data-classes')
    this.siblingsClass = element.getAttribute('data-siblings')
    
    const targetDomEl = document.querySelector(this.target)
    
    this.scrollBehabviour = element.getAttribute('data-scroll')
    
    this.element.addEventListener('click', (event) => {
      let elementAriaExpanded = (this.element.getAttribute('aria-expanded') === 'true')
      
      event.preventDefault()
      
      const siblings = [].slice.call(document.querySelectorAll(this.siblingsClass))
      
      if ( elementAriaExpanded ) {
        siblings.forEach(item => item.setAttribute('aria-expanded', 'false'))
        document.body.style.removeProperty('overflow')
      } else {
        siblings.forEach(item => item.setAttribute('aria-expanded', 'true'))
        document.body.style.overflow = 'hidden'
      }
      
      targetDomEl.classList.toggle(this.classes)
      
    }, false)
    
  }
}

export default ToggleElement
