<template>
  <div class="iframe-container">
    <figure class="iframe__poster figure-gradient" :class="[{'iframe__poster--playing': isPlaying}]" v-if="posterUrl">
      <img :src="posterUrl"
           alt="Poster"
           width="1280"
           height="720"
           ref="poster"
           loading="lazy">
      <button class="button iframe__play"
              type="button"
              value="Play Youtube Video"
              @click="playVideo">
        <i class="icon icon-play">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 25 27" enable-background="new 0 0 25 27" xml:space="preserve">
          <path fill="currentColor" d="M24,12.3L2.4,0.2C1.9-0.1,1.4-0.1,1,0.2C0.6,0.4,0.4,0.9,0.4,1.4v24.3c0,0.5,0.3,0.9,0.7,1.2
            C1.2,26.9,1.5,27,1.7,27c0.2,0,0.5-0.1,0.7-0.2L24,14.7c0.4-0.2,0.7-0.7,0.7-1.2S24.4,12.6,24,12.3z"/>
          </svg>
        </i>
        <span class="visually-hidden">Play Youtube Video</span>
      </button>
    </figure>
    <YouTube
      ref="youtube"
      :src="'https://www.youtube.com/watch?v=' + videoId"
      @state-change="onStateChange"
    />
  </div>
</template>

<script>

const calculateSize = function(url) {
  return new Promise(function(resolve, reject) {
    const image = document.createElement('img');
    image.addEventListener('load', function(e) {
      resolve({
        width: e.target.width,
        height: e.target.height,
      });
    });

    image.addEventListener('error', function() {
      reject('error');
    });

    image.src = url;
  });
};

import YouTube from 'vue3-youtube'

export default {
  name: "VYoutube",
  components: {
    YouTube
  },
  data() {
    return {
      isPlaying: false,
      posterUrl: null
    };
  },
  props: {
    videoId: String,
    defaultCover: String
  },

  mounted() {
    const linkElem = document.createElement('link');
    linkElem.setAttribute('rel', 'stylesheet');
    linkElem.setAttribute('href', document.querySelector('meta[name="app-css"]').content);

    this.$el.appendChild(linkElem);

    this.posterUrl = 'https://img.youtube.com/vi/' + this.videoId + '/maxresdefault.jpg'

    /**
     * check if poster is default youtube image
     * if it is default image, it is replaced with image from global options
    **/
    calculateSize(this.posterUrl)
      .then(data => {
        if (data.width == 120 && data.height == 90) {
          this.posterUrl = this.defaultCover
        }
      })
      .catch((error) => {
        console.error(error)
      })

  },

  methods: {
    //reset the video
    onStateChange (state) {
      if (state.data === 0) {
        this.$refs.youtube.player.seekTo(0);
        this.$refs.youtube.player.stopVideo();
        this.isPlaying = false
      }
    },

    /*endVideo() {
      console.log('ended')
      this.$refs.youtube.player.seekTo(0);
      this.$refs.youtube.player.stopVideo();
      this.isPlaying = false
    },*/

    playVideo() {
      this.$refs.youtube.player.playVideo();
      this.isPlaying = true
    }
  }
};
</script>
