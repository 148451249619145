//import getParents from '../utils/getParents'

const initMenuMobile = (options) => {
  let navigations = [].slice.call(document.querySelectorAll('.nav'))
  
  for (let nav of navigations) {
    nav.addEventListener('click', event => {
      
      let menuItemsOpened = [].slice.call(document.querySelectorAll('.nav__item--parent-opened'), 0)
      
      if (window.innerWidth < (options?.largeWidth ?? 1200)) {
        const target = event.target
        
        if (target.classList.contains('nav__item') || target.classList.contains('nav__link') || target.classList.contains('nav__text')) {
          return true
        }
        
        else if (target.closest('.nav__toggle')) {
         
          const parent = target.closest('.nav__item--parent')
          if (!parent.classList.contains('nav__item--parent-opened')) {
            for (let item of menuItemsOpened) {
              item.classList.remove('nav__item--parent-opened')
            }
          }
        
          if (parent &&
              parent.nodeName !== 'A' &&
              parent.nodeName === 'LI' &&
              parent.classList.contains('nav__item--has-children') &&
              parent.classList.contains('nav__item--parent')) {
    
            parent.classList.toggle('nav__item--parent-opened')
          }
        }
  
        
      }
      
    }, false)
    
  }
}


export default initMenuMobile
