// ADD LOADING ATTRIBUTE POLYFILL
require('loading-attribute-polyfill');

import isTouchDevice from 'is-touch-device'
import isMobile from 'ismobilejs';
const userAgent = window.navigator;
/*console.log(isMobile(userAgent).any);*/
import CraftVars from './services/craftVars';
import { defineCustomElement } from 'vue'
// component import
import VYoutube from './components/VYoutube/VYoutube.vue'
// convert into custom element constructor
const VYoutubeElement = defineCustomElement(VYoutube)
customElements.define('v-youtube', VYoutubeElement)


import VVimeo from './components/VVimeo/VVimeo.vue'
// convert into custom element constructor
const VVimeoElement = defineCustomElement(VVimeo)
customElements.define('v-vimeo', VVimeoElement)

import FilterProjects from './components/FilterProjects/FilterProjects.vue'
// convert into custom element constructor
const FilterProjectsEl = defineCustomElement(FilterProjects)
customElements.define('v-filter-projects', FilterProjectsEl)

import ToggleElement from './utils/ToggleElement.js'

const ToggleTriggers = [].slice.call(document.querySelectorAll('.toggle-trigger'), 0)
for (let trigger of ToggleTriggers) {
  new ToggleElement(trigger)
}

import initMenuMobile from './components/Menu/MenuMobile'
import initMenuDesktop from './components/Menu/Menu'

initMenuMobile({largeWWidth: 1200})
initMenuDesktop({
  largeWidth: 1200,
  subNavDomEl: "UL"
})

if ('loading' in HTMLImageElement.prototype) {
  const images = document.querySelectorAll('img[loading="lazy"]')
  for (let img of images) {
    if (img.dataset.src) {
      img.src = img.dataset.src
    }

    if (img.dataset.srcset) {
      img.srcset = img.dataset.srcset
    }

    if (img.dataset.sizes) {
      img.sizes = img.dataset.sizes
    }
  }
} else {
  document.body.classList.add('loading-not-supported')
  // Dynamically import the LazySizes library
  const script = document.createElement('script')
  script.src = CraftVars.baseUrl + '/assets/js/lazysizes.js'
  document.body.appendChild(script)
}
/*
import {CraftContent} from './api/craft/craftContent';
CraftContent.getEntries({
  section: "pages",
  limit: 10
}).then(data => {
  console.log(data)
})
*/
/*
import {HeaderScroll} from './components/HeaderScroll/HeaderScroll';
HeaderScroll()
*/
/*
import {LikeButton} from "./components/React/LikeButton"
LikeButton()

import {PagesList} from "./components/React/PagesList"
PagesList()

*/
import {HeaderScroll} from './components/HeaderScroll/HeaderScroll';
HeaderScroll();

(() => {
  let showMore = document.querySelector('.c-three_columns__show-more');
  if ( showMore ) {
    showMore.addEventListener('click',(e)=>{
      e.preventDefault();
      document.querySelectorAll('.c-three_columns__item').forEach( el => {
        if( el.classList.contains('hidden') ) {
          el.classList.remove('hidden');
          showMore.classList.add('hidden');
        }
      });
    });
  }
}
)();


/*import customSelect from 'custom-select';
const cEntries = [...document.querySelectorAll('.c-entries')]

for (let component of cEntries) {
  const filters = [...component.querySelectorAll('.filter')]
  const filterOptions = [];
  const filterQuery = [];

  for (let filter of filters) {
    const entries = [...component.querySelectorAll('.filterEntry')]
    const updateClass = "filterEntry--update";

    const select = filter.querySelector('select')
    customSelect(select)
    const cstSel =  select.customSelect
    cstSel.select.addEventListener('change', (e) => {

      console.log(e.target.name)

    });


  }

}*/


