import Menubar from './MenubarLinks'

// ARIA Compatible Menu
export const initMenuDesktop = (options) => {
  const menuBars = document.querySelectorAll('.menu')
  
  for (let menu of menuBars) {
    if (menu.querySelectorAll('.nav__item--has-children').length > 0) {
      let menuBar = new Menubar(menu)
      menuBar.init(options)
    }
  }
}

export default initMenuDesktop


