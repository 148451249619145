<template>
  <div class="iframe-container">
<!--    <figure v-if="posterUrl"
            class="iframe__poster figure-gradient"
            :class="[{'iframe__poster&#45;&#45;playing': isPlaying}]">
      <img :src="posterUrl"
           alt="Poster"
           width="1280"
           height="720"
           ref="poster"
           loading="lazy"/>
      <button class="button iframe__play"
              type="button"
              value="Play Youtube Video"
              @click="playVideo">
        <i class="icon icon-play">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 25 27" enable-background="new 0 0 25 27" xml:space="preserve">
          <path fill="currentColor" d="M24,12.3L2.4,0.2C1.9-0.1,1.4-0.1,1,0.2C0.6,0.4,0.4,0.9,0.4,1.4v24.3c0,0.5,0.3,0.9,0.7,1.2
            C1.2,26.9,1.5,27,1.7,27c0.2,0,0.5-0.1,0.7-0.2L24,14.7c0.4-0.2,0.7-0.7,0.7-1.2S24.4,12.6,24,12.3z"/>
          </svg>
        </i>
        <span class="visually-hidden">Play Youtube Video</span>
      </button>
    </figure>-->
    <vimeo-player ref="player"
                  :video-id="vimeoId.toString()"
                  @ready="onReady"
                  @playing="onPlaying"
                  @ended="onEnded"
                  :player-height="height"></vimeo-player>

  </div>
</template>

<script>
import { vueVimeoPlayer } from 'vue-vimeo-player'

export default {
  name: "VVimeo",
  compilerOptions: {

  },
  components: {
    vueVimeoPlayer
  },
  data () {
    return {
      posterUrl: null,
      options: {
        muted: true,
        autoplay: false
      },
      height: 500,
      isPlaying: false,
      playerReady: false,
    }
  },

  mounted() {
    const linkElem = document.createElement('link');
    linkElem.setAttribute('rel', 'stylesheet');
    linkElem.setAttribute('href', document.querySelector('meta[name="app-css"]').content);

    this.$el.appendChild(linkElem);

    fetch(`https://vimeo.com/api/v2/video/${this.vimeoId}.json`)
      .then(response => response.json())
      .then(data => {
        this.posterUrl = data[0].thumbnail_large
      })
  },

  methods: {
    onReady() {
      this.playerReady = true
    },
    play () {
      this.$refs.player.play()
    },
    pause () {
      this.$refs.player.pause()
    },

    playVideo () {
      this.play();
    },

    onEnded () {
      this.isPlaying = false
    },

    onPlaying () {
      this.isPlaying = true
    }
  },

  props: {
    vimeoId: Number
  }
}
</script>
