export const HeaderScroll = () => {

  let lastScrollTop = 0

  const header = document.querySelector('#header')

  window.addEventListener('scroll', () => {

    let st = window.pageYOffset || document.documentElement.scrollTop
    header.classList.toggle('header--scroll', st > 50 )
    lastScrollTop = st <= 0 ? 0 : st // For Mobile or negative scrolling

  }, {passive: true})
}
